import {
  RouteKind,
  serializeRoute,
  ApplicationRoute,
  Route,
  ApplicationRouteKind,
  ApplicationTab,
} from "./route";
import { ApplicationParameters } from "./bindings/ApplicationParameters";
import { ApplicationId } from "./bindings/ApplicationId";
import {
  getApplicationParameters,
  getApplicationParametersForRevision,
  postApplication,
  postApplicationParameters,
} from "./api/application";
import { useEffect, useState } from "react";
import { useDeviceType } from "./use-device-type";
import { CoverLetter } from "./cover-letter";
import { ControlPane } from "./control-pane";
import { startAnonymousSession } from "./api/anonymous-session";
import { useApplicationTitle } from "./application-title";
import "bootstrap-icons/font/bootstrap-icons.css";
import { cookieExists } from "./session-cookie-exists-hook";
import { Resume } from "./resume";
import { PlansPopup } from "./plans-popup";
import { DownloadLink, DownloadLinkTarget } from "./download-link";
import { ResumeChat } from "./resume-chat";
import { useLocation } from "wouter";
import { Tabs, TabItem } from "./tabs";
import { postUserMessage } from "./api/chat";
import { RESUME_CONTROL_SELECTOR } from "./joyride";
import { joyrideLocale } from "./joyride";
import { joyrideStyle } from "./joyride";
import { Step } from "react-joyride";
import Joyride from "react-joyride";

type ApplicationTitleProps = {
  revisionId: string | null;
};

function ApplicationTitle({ revisionId }: ApplicationTitleProps): JSX.Element {
  const title = useApplicationTitle(revisionId);

  return <h4 style={{ textAlign: "center" }}>{title}</h4>;
}

type ApplicationWizardProps = {
  applicationRoute: ApplicationRoute;
};
export function ApplicationWizard({
  applicationRoute,
}: ApplicationWizardProps): JSX.Element {
  const [showError, setShowError] = useState<boolean>(false);
  const [showPlansPopup, setShowPlansPopup] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<ApplicationTab>(
    applicationRoute.tab,
  );

  const [generationsWithNoResume, setGenerationsWithNoResume] =
    useState<number>(0);

  // If this element is mounted with a New application route, then it must create a new application
  // and navigate to the latest revision of that application.
  useEffect(() => {
    if (applicationRoute.kind !== ApplicationRouteKind.New) {
      return;
    }

    // TODO: Do we need to think about cancelling this effect in case the URL changes before it
    // finishes?
    (async function () {
      try {
        const isLoggedIn = cookieExists("user");
        if (!isLoggedIn) {
          await startAnonymousSession();
        }

        const applicationId = await postApplication();
        const route: Route = {
          kind: RouteKind.Application,
          applicationRoute: {
            kind: ApplicationRouteKind.LatestRevision,
            applicationId,
            tab: "controls",
          },
        };
        window.history.replaceState({}, "", serializeRoute(route));
      } catch (error) {
        console.error("Error creating new application", error);
        setShowError(true);
      }
    })();
  }, [applicationRoute]);

  const [applicationParameters, setApplicationParameters] =
    useState<ApplicationParameters>({
      jobAdvert: null,
      resumeId: null,
      wordCount: null,
      templateCoverLetterId: null,
      languageOverride: null,
      coverLetterRandomSeed: null,
      coverLetterText: null,
      applicantAddress: {
        name: null,
        streetAddress: null,
        postalCode: null,
        city: null,
      },
      companyAddress: {
        companyName: null,
        streetAddress: null,
        postalCode: null,
        city: null,
      },
      jsonResume: null,
      parentRevisionId: null,
      headshotId: null,
    });

  const applicationId: ApplicationId | null = (function () {
    switch (applicationRoute.kind) {
      case ApplicationRouteKind.New:
        return null;
      case ApplicationRouteKind.LatestRevision:
        return applicationRoute.applicationId;
      case ApplicationRouteKind.SpecificRevision:
        return applicationRoute.applicationId;
      default: {
        const exhaustive: never = applicationRoute;
        throw new Error(`Unhandled application route kind: ${exhaustive}`);
      }
    }
  })();

  // This is the RevisionID that we're displaying. In case we're on the LatestRevision route,
  // we need to fetch this, since the ID is not in the route then. In case we're on the SpecificRevision route
  // though we can use the revisionId from the route directly.
  // This will be `null` either if we're on the New route (so we don't even have an ApplicationId
  // yet), or if the application has just been created and doesn't have a revision yet.
  const [revisionId, setRevisionId] = useState<string | null>(null);
  useEffect(() => {
    (async function () {
      switch (applicationRoute.kind) {
        case ApplicationRouteKind.New:
          break;
        case ApplicationRouteKind.LatestRevision: {
          // TODO: I believe this runs every time we update the parameters, since we're updating
          // the browser history when that happens, which shortly changes the URL. If I'm right,
          // then we should do something to avoid fetching the latest revision in that common case.
          // TODO: Replace this with an API that gives us the latest parameters as well as the
          // revision ID.
          const revision = await getApplicationParameters(
            applicationRoute.applicationId,
          );
          setRevisionId(revision.revisionId?.revisionId ?? null);
          setApplicationParameters(revision.applicationParameters);
          break;
        }
        case ApplicationRouteKind.SpecificRevision:
          const applicationParameters =
            await getApplicationParametersForRevision(
              applicationRoute.applicationId,
              applicationRoute.revisionId,
            );
          setRevisionId(applicationRoute.revisionId.revisionId);
          setApplicationParameters(applicationParameters);
          break;
        default: {
          const exhaustive: never = applicationRoute;
          throw new Error(`Unhandled application route kind: ${exhaustive}`);
        }
      }
    })();
  }, [applicationRoute]);

  const [, setLocation] = useLocation();
  function updateRevisionId(newRevisionId: string) {
    if (applicationId == null) {
      throw new Error("Application ID must be set before setting revision");
    }
    if (revisionId === newRevisionId) {
      return;
    }
    const newRoute: Route = {
      kind: RouteKind.Application,
      applicationRoute: {
        kind: ApplicationRouteKind.SpecificRevision,
        applicationId,
        revisionId: { revisionId: newRevisionId },
        tab: "controls",
      },
    };
    const newUrl = serializeRoute(newRoute);
    setLocation(newUrl);
  }

  const pushApplicationParameters =
    applicationId == null
      ? null
      : async function pushApplicationParameters(
          update: (old: ApplicationParameters) => ApplicationParameters,
        ): Promise<void> {
          try {
            if (applicationId == null) {
              // TODO: Can we somehow ensure statically that this function is not called before we have
              // an application ID?
              throw new Error(
                "TODO: This must not be called before we have an application ID",
              );
            }

            // TODO: Consider updating the application parameters eagerly here so that there's no lag.
            // TODO: No error handling here yet.
            let newParameters = update(applicationParameters);
            newParameters.parentRevisionId = revisionId
              ? { revisionId: revisionId }
              : null;
            const newRevisionId = await postApplicationParameters(
              applicationId,
              newParameters,
            );
            setRevisionId(newRevisionId?.revisionId ?? null);
            setApplicationParameters(newParameters);

            if (newRevisionId !== null) {
              updateRevisionId(newRevisionId.revisionId);
              if (newParameters.resumeId === null) {
                setGenerationsWithNoResume(generationsWithNoResume + 1);
              }
            }
          } catch (err) {
            console.error("Error updating application parameters", err);
            setShowError(true);
          }
        };

  const isMobile = useDeviceType();

  // This is only relevant when we're on the mobile layout.
  // TODO: Can this be refactored into a separate component?
  const [controlsExpanded, setControlsExpanded] = useState<boolean>(false);
  useEffect(() => {
    if (isMobile && revisionId == null) {
      setControlsExpanded(true);
    }
  }, [isMobile, revisionId]);
  useEffect(() => {
    if (!isMobile) {
      setControlsExpanded(false);
    }
  }, [isMobile]);

  const containerStyle: React.CSSProperties = isMobile
    ? {
        paddingTop: 32,
        paddingBottom: 32,
      }
    : {
        display: "flex",
        flexDirection: "row",
        gap: 16,
        paddingLeft: 16,
        paddingRight: 16,
        paddingTop: 32,
        paddingBottom: 32,
      };

  const controlPaneStyle: React.CSSProperties = isMobile
    ? {
        position: "fixed",
        bottom: 0,
        width: "100%",
        maxHeight: "50vh",
        overflowY: "auto",
        paddingRight: 4,
        paddingLeft: 4,
        borderTop: "1px solid #e9ecef",
        boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.1)",
        zIndex: 100,
        backgroundColor: "white",
      }
    : {
        display: "flex",
        flexDirection: "column",
        gap: 32,
        minWidth: "25%",
        position: "sticky",
        top: 0,
        height: "100vh",
        overflowY: "auto",
        paddingBottom: "16px",
      };

  const contentPaneStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 32,
  };

  const pageStyle: React.CSSProperties = isMobile
    ? {}
    : {
        minHeight: "297mm",
        width: "210mm",
        border: "1px solid black",
        borderRadius: 4,
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
      };

  const resumeChat =
    applicationId !== null ? (
      <ResumeChat
        revisionId={revisionId}
        setRevisionId={updateRevisionId}
        applicationId={applicationId}
        applicationParameters={applicationParameters}
      />
    ) : null;

  const postUserMessageAndOpenChat =
    applicationId !== null
      ? async function postUserMessageAndOpenChat(message: string) {
          await postUserMessage(applicationId, message);
          onTabChange("chat");
        }
      : null;
  const controlPane = (
    <ControlPane
      applicationParameters={applicationParameters}
      pushApplicationParameters={pushApplicationParameters}
      postMessageAndOpenChat={postUserMessageAndOpenChat}
    />
  );

  const controlTabs: TabItem<ApplicationTab>[] = [
    {
      id: "controls",
      title: "Bewerbung",
      content: controlPane,
    },
    {
      id: "chat",
      title: "Chat",
      content: resumeChat,
    },
  ];

  const onTabChange = (tab: ApplicationTab) => {
    const newRoute: Route = {
      kind: RouteKind.Application,
      applicationRoute: {
        ...applicationRoute,
        tab,
      },
    };
    const newUrl = serializeRoute(newRoute);
    setLocation(newUrl);
    setActiveTab(tab);
  };

  const noResumeNotification: Step[] = [
    {
      target: `.${RESUME_CONTROL_SELECTOR}`,
      content:
        "Um noch bessere Anschreiben zu erhalten, laden Sie Ihren Lebenslauf hoch. So können wir in Ihrem Anschreiben auf Ihre persönliche Stärken und Erfahrungen eingehen.",
      disableBeacon: true,
      placement: "right",
    },
  ];

  return (
    <div style={{ paddingTop: 32 }}>
      <ApplicationTitle revisionId={revisionId} />
      {showError && (
        <div className="mb-3 alert alert-danger" role="alert">
          Ein Fehler ist aufgetreten. Laden Sie die Seite neu, um fortzufahren.
        </div>
      )}
      {showPlansPopup && (
        <PlansPopup
          onHide={() => setShowPlansPopup(false)}
          title="Nur für Kunden von Bewerbungshelfer Professional"
        />
      )}
      <div style={containerStyle}>
        <div style={controlPaneStyle}>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "end",
                height: "fit-content",
              }}
            >
              <div style={{ flex: 1, alignItems: "baseline" }}>
                <ApplicationTitle revisionId={revisionId} />
              </div>
              <button
                className="btn"
                onClick={() => setControlsExpanded(!controlsExpanded)}
              >
                {controlsExpanded ? "⌄" : "📝"}
              </button>
            </div>
          ) : null}
          <div
            hidden={isMobile && !controlsExpanded}
            style={{ height: "100%" }}
          >
            <Tabs
              tabs={controlTabs}
              activeTab={activeTab}
              onTabChange={onTabChange}
            />
          </div>
        </div>
        <div style={contentPaneStyle}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: 64,
            }}
          >
            <div
              style={{
                paddingBottom: "25mm",
                ...pageStyle,
              }}
            >
              <Joyride
                steps={noResumeNotification}
                disableScrolling={true}
                run={
                  applicationParameters.resumeId === null &&
                  generationsWithNoResume > 2
                }
                continuous={true}
                hideBackButton={true}
                styles={joyrideStyle}
                locale={joyrideLocale}
              />
              <CoverLetter
                revisionId={revisionId}
                // TODO: actually adapt cover letter based on template
                layout={"dualColumn"}
                setShowError={setShowError}
                setShowPlansPopup={setShowPlansPopup}
                pushApplicationParameters={pushApplicationParameters}
              />
            </div>
          </div>

          <div style={{ position: "relative", ...pageStyle }}>
            <Resume
              revisionId={revisionId}
              pushApplicationParameters={pushApplicationParameters}
              layout={"singleColumn"}
            />
            <div
              style={{
                position: "absolute",
                bottom: "25mm",
                left: "50%",
                transform: "translateX(-50%)",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DownloadLink
                revisionId={revisionId}
                target={DownloadLinkTarget.Resume}
                setShowPlansPopup={setShowPlansPopup}
                layout={"singleColumn"}
              />
            </div>
          </div>
          <div style={{ position: "relative", ...pageStyle }}>
            <Resume
              revisionId={revisionId}
              pushApplicationParameters={pushApplicationParameters}
              layout={"dualColumn"}
            />
            <div
              style={{
                position: "absolute",
                bottom: "25mm",
                left: "50%",
                transform: "translateX(-50%)",

                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <DownloadLink
                revisionId={revisionId}
                target={DownloadLinkTarget.Resume}
                setShowPlansPopup={setShowPlansPopup}
                layout={"dualColumn"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
